import React from "react";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookIcon, LinkedinIcon, TwitterIcon} from 'react-share'

const SocialSharingBlock = ({className, location, iconSize = 32}) => {

    const classes = ['hudl-casestudy__social-icons', className];

    return (
        <div className={classes.join(" ")}>
            <FacebookShareButton url={location.href} className="hudl-margin-right--xs">
                <FacebookIcon size={iconSize} />
            </FacebookShareButton>
            <LinkedinShareButton url={location.href} className="hudl-margin-right--xs">
                <LinkedinIcon size={iconSize} />
            </LinkedinShareButton>
            <TwitterShareButton url={location.href}>
                <TwitterIcon size={iconSize} />
            </TwitterShareButton>
        </div>
    );
};

export default SocialSharingBlock;

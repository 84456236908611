import React, {useEffect, useState} from 'react';
import ColonFix from '@components/atoms/ColonFix';
import Container from '@components/atoms/Container';
import Image from '@components/atoms/Image';
import SocialSharingBlock from '@components/molecules/SocialSharingBlock';
import FlexibleFields from '@components/organisms/FlexibleFields';
import Footer from '@components/organisms/footer';
import Layout from '@components/organisms/Layout';
import Navigation from '@components/organisms/navigation';
import SiteEnvVars from '@containers/EnvironmentVariables';
import SEO from '@containers/SEO';
import fontSetting from '@helpers/fontSettings';
import {getCookie, reloadOTBanner} from '@helpers/oneTrustGTM';
import pageUrlCleaner from '@helpers/pageUrlCleaner';
import GoogleFontLoader from 'react-google-font-loader';
import langPage from '../../languageCodes.json';


import "./style.case-study.scss";
import textProcessor from "../helpers/mikanTextSplitter";

const CaseStudyTemplate = props => {
    const {
        pageContext: { page, breadcrumb },
        location
    } = props

    let pageLanguages = null;
    let pagePath = null;

    const cleanUrl = pageUrlCleaner({path: page.path})
    if (typeof langPage[cleanUrl] === 'undefined') {
        throw new Error('The URL does not have any languages associated.');
    }
    pageLanguages = langPage[cleanUrl];
    pagePath = cleanUrl;

    const back = () => {
        if (window.previousOrigin === window.location.origin) {
            // If we navigated from somewhere else on the website, go to that page.
            window.history.back();
        } else {
            // If we navigated from an external page, go to the homepage.
            window.location.href = window.location.origin;
        }
    }

    const [loaded, setLoaded] = useState(false);
    useEffect(() => setLoaded(true), []);

    const {
        seoMetadata,
        featuredImage
    } = page;


    return (
        <Layout page={page}>
            <SiteEnvVars env={process.env.GATSBY_GTM_SITE_ENVIRONMENT} />
            {loaded && <GoogleFontLoader fonts={fontSetting(page.language)} />}
            {loaded && (
                getCookie(),
                reloadOTBanner()
            )}
            <SEO pageLangs={pageLanguages} pagePath={pagePath} pageMeta={seoMetadata} pageTitle={page.title} breadcrumb={breadcrumb} lang={page.language} />
            <Navigation pageLangs={pageLanguages} lang={page.language} />
            <Container>

                <header>
                    <button className="hudl-button hudl-button--tertiary-button hudl-margin-top--md hudl-case-study-back-button" onClick={back} type="button">
                        Back
                    </button>
                    <div className="hudl-margin-y--lg">
                        {page.pretitle &&
                            <h3 className="hudl-delta-type hudl-pretitle">{textProcessor(page.language, page.pretitle)}</h3>}
                        <h1 className='hudl-alpha-type'><ColonFix string={page.title} lang={page.language} /></h1>
                    </div>
                    <SocialSharingBlock className='hudl-margin-bottom--xl' location={location} />
                </header>

            </Container>
            <div className="hudl-case-study__featured-image">
                <Image imgsrc={featuredImage.image} title={featuredImage.imageTitle} alt={featuredImage.imageAlt} />
            </div>
            <FlexibleFields lang={page.language} fields={page.flexibleFields} />
            <Footer pageLangs={pageLanguages} lang={page.language} />
        </Layout>
    )
}

export default CaseStudyTemplate;

